import logo from './logo.svg';
import './App.css';
import FirstScreen from './screens/FirstScreen';
import SecondScreen from './screens/SecondScreen';
import BasicAccordion from './components/Akordion';
import ThirdPage from './screens/ThirdScreen';
import DetailsPage from './screens/DetailsPage';

function App() {
return <>
<FirstScreen/>
<SecondScreen/>

<ThirdPage/>

<DetailsPage/>
</>
}

export default App;
